(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

// legacy browser alert
function isLegacyIE() {
  var ua = navigator.userAgent;

  if (ua.indexOf("MSIE 6.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 7.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 8.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 9.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 10.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else {
    return false;
  }
}

isLegacyIE(); //TOP MV Swiper

setTimeout(function () {
  var MvSlider = new Swiper('.top-slider', {
    loop: true,
    effect: 'fade',
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    },
    speed: 2000,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    breakpoints: {
      767: {
        spaceBetween: 80
      }
    }
  });
}, 1000); //ページ内リンクスムーススクロール

$(function () {
  var headerHight = 100; //ヘッダの高さ
  // #で始まるアンカーをクリックした場合に処理

  $('a[href^="#"]').click(function () {
    // スクロールの速度
    var speed = 800; // ミリ秒
    // アンカーの値取得

    var href = $(this).attr("href"); // 移動先を取得

    var target = $(href == "#" || href == "" ? "html" : href); // 移動先を数値で取得

    var position = target.offset().top - headerHight; //ヘッダの高さ分位置をずらす
    // スムーススクロール

    $("body,html").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });
}); //下から表示させる要素を指定

var $pagetop = $('.__totop');
$(window).on('scroll', function () {
  //スクロール位置を取得
  if ($(this).scrollTop() < 100) {
    $pagetop.removeClass('active');
  } else {
    $pagetop.addClass('active');
  }
}); //モーダルウィンドウ

$(function () {
  $('#openModal').click(function () {
    $('#modalArea').fadeIn();
  });
  $('#closeModal , #modalBg').click(function () {
    $('#modalArea').fadeOut();
  });
}); //ハンバーガメニュー

(function ($) {
  $(function () {
    var $header = $('#header'); // Nav Toggle Button

    $('#nav_toggle').click(function () {
      $header.toggleClass('open');
    });
    $('nav a').on('click', function () {
      if (window.innerWidth <= 768) {
        $header.removeClass('open');
      }
    });
  });
})(jQuery); //アニメーション


var fadeInTarget = document.querySelectorAll('.fade-in');
window.addEventListener('scroll', function () {
  for (var _i = 0; _i < fadeInTarget.length; _i++) {
    var rect = fadeInTarget[_i].getBoundingClientRect().top;

    var scroll = window.pageYOffset || document.documentElement.scrollTop;
    var offset = rect + scroll;
    var windowHeight = window.innerHeight; // 現在のブラウザの高さ

    if (scroll > offset - windowHeight + 150) {
      fadeInTarget[_i].classList.add('scroll-in');
    }
  }
}); //ローディングアニメーション

var images = document.getElementsByTagName('img');
var loadingBar = document.getElementById('loading_bar');
var loadingWrap = document.getElementById('loading_barWrap');
var loadingArea = document.getElementById('loading');
var imgLen = images.length;
var barLen = 100 / imgLen;
console.log(barLen);
var barWidth = 0;

for (var i = 0; i < imgLen; i++) {
  images[i].onload = function () {
    barWidth = barWidth + barLen;
    loadingBar.style.width = barWidth + '%';
  };
}

function loadLen() {
  if (!loadingArea.classList.contains('loadingNone')) {
    if (loadingBar.clientWidth === loadingWrap.clientWidth) {
      loadingArea.classList.add('loadingNone');
    } else {
      setTimeout(loadLen, 1000);
    }
  }
} // 100%にならなかった時用の処理


window.addEventListener('load', function () {
  if (!loadingArea.classList.contains('loadingNone')) {
    loadingBar.style.width = '100%';
    setTimeout(function () {
      loadingArea.classList.add('loadingNone');
    }, 1000);
  }
});

},{}]},{},[1]);
